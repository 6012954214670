<template>
  <b-row align-h="center">
    <b-col md="8">
      <validation-observer
        v-slot="{ handleSubmit }"
      >
        <b-card header="Add Update">
          <b-form @submit.prevent="handleSubmit(saveUpdate)">
            <validation-provider
              #default="validationContext"
              name="IOS Minimum Version"
              rules="required|min:1|max:50"
            >
              <b-form-group
                label="IOS Minimum Version"
                label-for="ios-minimum-version"
              >
                <b-form-input
                  id="ios-minimum-version"
                  v-model="updateData.iosMinVersion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Android Minimum Version"
              rules="required|min:1|max:50"
            >
              <b-form-group
                label="Android Minimum Version"
                label-for="android-minimum-version"
              >
                <b-form-input
                  id="android-minimum-version"
                  v-model="updateData.androidMinVersion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Update Message"
              rules="required|min:3|max:250"
            >
              <b-form-group
                label="Message"
                label-for="message"
              >
                <b-textarea
                  id="message"
                  v-model="updateData.message"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <back />
          </b-form>
        </b-card>
      </validation-observer>
    </b-col>
  </b-row>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddUpdates',
  components: {
    Back,
  },
  data() {
    return {
      updateData: {},
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      successfulOperationAlert,
    }
  },
  created() {
    this.getUpdateData()
  },
  methods: {
    getUpdateData() {
      this.$http.get(`${process.env.VUE_APP_PRAYERS_BASE_URL}/version`).then(res => {
        this.updateData = res.data.data
      })
    },
    saveUpdate() {
      this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/save-version`, this.updateData).then(() => {
        this.successfulOperationAlert('Latest update info is edited successfully')
      })
    },
  },
}
</script>
<style lang="">

</style>
